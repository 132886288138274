import Cookies from 'js-cookie'
import base64url from 'base64url'

const TokenKey = 'token'
const AutoLogin = 'al'

const state = {
  autoLogin: true,
  id: undefined,
  uid: undefined,
  token: undefined,
  expiry: 0,
  name: '',
  email: '',
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  introduction: '',
  roles: []
}

const mutations = {
  SET_AUTO_LOGIN: (state, whethere) => {
    state.autoLogin = whethere
    Cookies.set(AutoLogin, whethere, { expires: 30 })
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_UID: (state, id) => {
    state.id = id
  },
  SET_TOKEN: (state, token) => {
    const t = token.split('.')
    if (t.length != 3) {
      return
    }

    state.token = token
    if (state.autoLogin) {
      Cookies.set(TokenKey, token, { expires: 30 })
    }

    const p = JSON.parse(base64url.decode(t[1]))
    state.id = p['id']
    state.uid = p['iss']
    state.name = p['name']
    state.expiry = p['exp']
    state.roles = p['roles']
  },
  SET_EXPIRY: (state, expiry) => {
    state.expiry = expiry
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_AVATAR: (state, avatar) => {
    if (avatar === 'undefined') { return }
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  setAutoLogin({commit}, whethere) {
    commit('SET_AUTO_LOGIN', whethere)
  },
  setToken({commit}, token) {
    commit('SET_TOKEN', token)
  },
  // user login
  /*login({ commit }, userInfo) {
    const { username, password, saveLoginInfo } = userInfo

    // check save login info
    let hashedPassword = state.hashedPd
    if (!saveLoginInfo || password !== '*-**-***-**-*') {
      //hashedPassword = new MD5().update(password).digest('hex').toUpperCase()
    }

    // clean saved info
    if (!saveLoginInfo && hasSavedLoginInfo()) {
      removeSavedLoginInfo()
    }

    return new Promise((resolve, reject) => {
      login({ userId: username.trim(), password: hashedPassword })
        .then(response => {
          const { name, token, expiry } = response.content
          commit('SET_TOKEN', token)
          setToken(token)

          commit('SET_ID', username)
          setId(username)

          commit('SET_PD', hashedPassword)
          if (saveLoginInfo) { setLoginInfo(username, hashedPassword) }

          commit('SET_NAME', name)

          commit('SET_EXPIRY', expiry)

          resolve(response.content)
        }).catch(error => {
          reject(error)
        })
    })
  },*/

  // get user info
  /*getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo({ commit }).then(response => {
        const { content } = response

        // avatar
        const { actions, roles, name, dept, oddi, email } = content

        commit('SET_NAME', name)
        commit('SET_AS', actions)
        commit('SET_ROLES', roles)
        commit('SET_DEPT', dept)
        commit('SET_ODDI', oddi)
        commit('SET_CURRENT_ORGAN', [dept.id])
        commit('SET_AVATAR', decodeURIComponent(oddi.avatar))
        commit('SET_EMAIL', email)

        if (!actions || actions.length <= 0) {
          reject(i18n.t('login.noActionError'))
          return
        }

        resolve(content)
      }).catch(error => {
        reject(error)
      })
    })
  },*/

  // user logout
  logout({ commit /*, state, dispatch*/ }) {
    commit('SET_AUTO_LOGIN', false)
    commit('SET_ID', '')
    commit('SET_EXPIRY', 0)
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    Cookies.remove(TokenKey)
    Cookies.remove(AutoLogin)
  }
}

const getters = {
  autoLogin: state => state.autoLogin,
  id: state => state.id,
  token: state => state.token,
  expiry: state => state.expiry,
  roles: state => state.roles,
  name: state => state.name,
  avatar: state => state.avatar
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
getters}
