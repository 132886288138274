import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'underscore'
import Cookies from 'js-cookie'

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  state: {
    am_total: {
      total: 1321,
      live: 112
    },
    lives: [],
    liveIds: [],
    ranks: [],
    map_conf: {'center': {'lat': 25.43197588002793,'lng': 101.73899656599349,'height': 0},'pitch': 43.5,'zoom': 5,
    'rotation': 0,'viewMode': '3D','baseMap': {'type': 'satellite'}, 'styleId': 'style3'}
  },
  mutations: {
    UDP_TOTAL: (state, am) => {
      // console.log('------mutations:UDP_TOTAL', am)
      state.am_total = am
    },
    UDP_LIVE: (state, live) => {
      state.lives = live

      const ids = _.pluck(live, 'id')
      state.liveIds = ids
    // console.log('------mutations:UDP_live', live, ids)
    },
    UDP_RANK: (state, rank) => {
      state.ranks = rank
    },
    SAVE_MAP_CONF: (state, conf) => {
      state.map_conf = conf
    }
  },
  actions: {
    update_total({commit}, total) {
      // console.log('------actions:update_total', total)
      commit('UDP_TOTAL', total)
    },
    update_lives({commit}, live) {
      // console.log('------actions:update_lives', live)
      commit('UDP_LIVE', live)
    },
    update_ranks({commit}, rank) {
      //console.log('------actions:update_ranks', rank)
      commit('UDP_RANK', rank)
    },
    save_map_conf({commit}, conf) {
      commit('SAVE_MAP_CONF', conf)
    }
  },
  // modules: {
  // },
  getters: {
    am_total: state => state.am_total,
    am_live: state => state.lives,
    am_live_ids: state => state.liveIds,
    am_rank: state => state.ranks,
    map_conf: state => state.map_conf
  }
})

// load cookie
const al = Cookies.get('al')
const token = Cookies.get('token')
store.dispatch('user/setAutoLogin', al)
if (token) {
  store.dispatch('user/setToken', token)
}

export default store
