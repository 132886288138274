import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/login',
  name: 'login',
  component: () => import('../views/login/index.vue')
},
{
  path: '/auth-redirect',
  name: 'redirect',
  component: () => import('../views/login/auth-redirect.vue')
},
{
  path: '/sys/user',
  component: () => import('@/views/user/components/UserNav'),
  redirect: '/sys/user/list',
  children:[
    {
      path: '/sys/user/list',
      name: 'user',
      component: () => import('../views/user/list.vue')
    }
  ]
},
{
  path: '/am/mg',
  component: () => import('@/views/am/components/nav'),
  redirect: '/am/list',
  children:[
    {
      path: '/am/list',
      name: 'amList',
      component: () => import('../views/am/list.vue')
    }
  ]
},
]
const router = new VueRouter({
  routes
})

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  if (checkToken()) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      //NProgress.done()
      return
    }

    next()
    return
  }

  //!!! has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      //NProgress.done()
    }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

import store from '@/store'
function checkToken() {
  const token = store.getters['user/token']
  const expiry = store.getters['user/expiry']

  return token && (expiry >= Date.now() /1000)
}

export default router