import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'

// 引入全局css
import './assets/scss/style.scss'
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/chart-bar.js'
import 'vue-awesome/icons/chart-area.js'
import 'vue-awesome/icons/chart-pie.js'
import 'vue-awesome/icons/chart-line.js'
import 'vue-awesome/icons/align-left.js'
import 'vue-awesome/icons/sliders-h'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/eye-slash'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/backspace'

// 引入echart
// 4.x 引用方式
import * as echarts from 'echarts'
// 5.x 引用方式为按需引用
// 希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
// import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// 全局注册
Vue.component('icon', Icon)
Vue.use(dataV)

import 'element-theme-dark'
import '@/styles/element-variables.scss'
import '@/styles/index.scss' // global css
import Element from 'element-ui'
// import { MessageBox } from 'element-ui'

Vue.use(Element)

// use underscore with this._.
import underscore from 'underscore'
Object.defineProperty(Vue.prototype, '_', { value: underscore })

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

// 优先渲染界面
// init socket client
import io from 'socket.io-client'
const socket = io('/ws', {
  // 正式系统
  // path: '/ws',
  query: {
    uid: 'u' + Math.random()
  },
  extraHeaders: {
    'X-uid': 'nmvn$jk44j&j65kjf45akd32jff44dkf21aaf32dfj34a23j=='
  },
//    transports: ["websocket"],
//    upgrade: false
})
socket.on('connect', function () {
  console.log('连接到服务器')
})
socket.on('disconnect', function () {
  console.log('连接断开')
})
window['__debug__'] = false
socket.on('total:update', (result) => {
  if (window['__debug__']) {
    console.log('update total:', result)
  }

  store.dispatch('update_total', result)
})
socket.on('ranks:update', result => {
  if (window['__debug__']) {
    console.log('update ranks:', result)
  }

  store.dispatch('update_ranks', result)
})
socket.on('live:update', result => {
  if (window['__debug__']) {
    console.log('update live:', result)
  }

  store.dispatch('update_lives', result)
})

Vue.prototype.$io = socket
